import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { BARCODE_SCANNER_STATES } from 'src/utils/constants';

const Barcode = ({ hidden, children, scannerState, barcodeMedium }) => {
  const barcodeViewerClasses = classNames('barcode-viewer', {
    'barcode-medium': barcodeMedium,
  });

  const barcodeInactiveClasses = classNames('barcode-inactive', {
    'barcode-medium': barcodeMedium,
  });

  if (hidden) {
    return (
      <div className='barcode-reader-content w-100 h-100 d-flex align-items-center justify-content-center flex-column bg-success'>
        <FontAwesomeIcon icon={faCheckCircle} size='5x' />
        {children}
      </div>
    );
  }

  return scannerState === BARCODE_SCANNER_STATES.inactive ? (
    <div className={barcodeInactiveClasses} />
  ) : (
    <div className={barcodeViewerClasses} />
  );
};

Barcode.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node,
  scannerState: PropTypes.string.isRequired,
  barcodeMedium: PropTypes.bool,
};

Barcode.defaultProps = {
  hidden: false,
  children: '',
  barcodeMedium: false,
};

export default Barcode;
