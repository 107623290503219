import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Alert from 'src/components/alert';
import Barcode from 'src/components/barcode';
import BarcodeStatusAlert from 'src/components/BarcodeStatusAlert';
import Loader from 'src/components/loader';
import ScanButton from 'src/components/ScanButton';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import useBarcodePanelStore from 'src/stores/useBarcodePanelStore';
import { BARCODE_SCANNER_STATES, SCAN_BUTTON_STATES } from 'src/utils/constants';

// Threshold in milliseconds
const KEY_PRESS_THRESHOLD = 50;
// Time in milliseconds to wait for the Barcode Full Code received
const BARCODE_SCAN_INPUT_DELAY = 500;

const BarcodeScan = ({ instructionText, placeholder }) => {
  const {
    scannedBarcodeValue,
    setScannedBarcodeValue,
    resetBarcodeValue,
    showInstructions,
    setBarcodeError,
    barcodeError,
    errorMessage,
  } = useBarcodePanelStore();

  const { closeActionPanel } = useActionPanelStore();

  const barcodeInputRef = useRef(null);
  const barcodeScanTimeoutRef = useRef(null);
  const lastKeypressTime = useRef(Date.now());
  const [lastScannedBarcodeValue, setLastScannedBarcodeValue] = useState('');
  const [state, setState] = useState(SCAN_BUTTON_STATES.initialized);
  const [scannerState, setScannerState] = useState(BARCODE_SCANNER_STATES.ready);
  const [successfulScan, setSuccessfulScan] = useState(false);

  const isLoading = state === SCAN_BUTTON_STATES.loading;
  const isSearchDisabled = [SCAN_BUTTON_STATES.loading, SCAN_BUTTON_STATES.success].includes(state);

  const focusBarcodeInput = () => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };

  const checkIfBarcodeInputIsNotFocused = () => {
    // Check if the "Barcode Output" input is not the currently focused element
    if (document.activeElement !== barcodeInputRef.current) {
      setScannerState(BARCODE_SCANNER_STATES.inactive);
    }
  };

  const setActiveScannerState = () => {
    // If the user scanned something when the input was not focused ->
    // Show "Idle Status" first, otherwise - Ready status.
    if (scannerState !== BARCODE_SCANNER_STATES.idle) {
      return setScannerState(BARCODE_SCANNER_STATES.ready);
    }
  };

  const handleCheckTypeOfInput = event => {
    // Get the time between the last two keypress
    const timeBetweenKeystrokes = Date.now() - lastKeypressTime.current;
    // Update the last keypress time
    lastKeypressTime.current = Date.now();
    // Check if the key pressed was the Enter key
    const wasEnterKeyTriggered = event.key === 'Enter';
    // Assume it's a scanner if time between keystrokes is very short
    // If true - the scanner input will be defined
    // And the Enter key was pressed
    return timeBetweenKeystrokes < KEY_PRESS_THRESHOLD && wasEnterKeyTriggered;
  };

  const handleResetBarcodeState = (
    shouldResetBarcodeState = true,
    barcodeValue = '',
    lastScannedValue
  ) => {
    setScannedBarcodeValue({ scannedBarcodeValue: barcodeValue });
    if (shouldResetBarcodeState) {
      setScannerState(BARCODE_SCANNER_STATES.ready);
    }
    if (lastScannedValue) {
      setLastScannedBarcodeValue(lastScannedValue);
    }
  };

  const handleBarcodeScan = event => {
    clearTimeout(barcodeScanTimeoutRef.current);
    const currentBarcodeValue = event.target.value;

    if (scannerState !== BARCODE_SCANNER_STATES.idle) {
      // If the scanner is not idle, set it to idle and focus the input
      // Otherwise, leave the "Idle Status" until the user scan something again.
      setScannerState(BARCODE_SCANNER_STATES.ready);
    }

    if (!currentBarcodeValue) {
      // Nothing was scanned or manually typed, empty value.
      return;
    }

    // If there was an error, reset it. And process the correct Scanner state.
    setBarcodeError({ errorMessage: '' });
    handleResetBarcodeState(true, currentBarcodeValue);

    barcodeScanTimeoutRef.current = setTimeout(() => {
      // Get the full barcode value (debounced)
      console.info('Scanned Barcode Value:', currentBarcodeValue);

      setState(SCAN_BUTTON_STATES.success);
      setScannedBarcodeValue({ scannedBarcodeValue: currentBarcodeValue });
      setSuccessfulScan(true);
      // Clear the barcode and input for the next scan
      closeActionPanel();
    }, BARCODE_SCAN_INPUT_DELAY);
  };

  const handleConfirmScanManually = () => {
    if (!scannedBarcodeValue) {
      return setBarcodeError({ errorMessage: 'Please scan or type the barcode value' });
    }
    setSuccessfulScan(true);
    closeActionPanel();
  };

  const handleScanBarcodeOutput = event => {
    // Check whether it was the scanner action or manual input.
    const isScannerInput = handleCheckTypeOfInput(event);

    if (isScannerInput) {
      // Handle scanner input logic.
      return handleBarcodeScan(event);
    }
    // Handle manual input logic.
    return setScannedBarcodeValue({ scannedBarcodeValue: event.target.value });
  };

  const handleGlobalBarcodeScan = event => {
    // Check if the user used the scanner.
    const isBarcodeScan = handleCheckTypeOfInput(event);

    if (isBarcodeScan) {
      // Input Value for the scanner is not focused
      if (document.activeElement !== barcodeInputRef.current) {
        // Set scanner state to idle and focus the input if it's not already focused
        setScannerState(BARCODE_SCANNER_STATES.idle);
        // Set the focus back to the input
        focusBarcodeInput();
      }
    }
  };

  // Action to set the focus back on "Click here to scan" button.
  const handleManualFocus = () => {
    focusBarcodeInput();
    setActiveScannerState(BARCODE_SCANNER_STATES.ready);
    resetBarcodeValue();
  };

  useEffect(() => {
    const handleKeyPress = event => {
      // Focus the input if it is not focused initially.
      checkIfBarcodeInputIsNotFocused();
      // Handle the global barcode scan.
      handleGlobalBarcodeScan(event);
    };

    document.addEventListener('keypress', handleKeyPress);
    focusBarcodeInput();

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <>
      <div className='barcode-reader barcode-container-medium text-white bg-dark rounded-sm overflow-hidden mb-3'>
        <Barcode barcodeMedium hidden={successfulScan} scannerState={scannerState}>
          <Loader inline text='data' className='mt-3' />
        </Barcode>
      </div>

      <BarcodeStatusAlert
        scannerState={scannerState}
        successfulScan={successfulScan}
        focusInput={handleManualFocus}
      />

      {!successfulScan && (
        <section className='barcode-input'>
          <label>Barcode Output</label>
          <div className='input-group mb-3'>
            <input
              ref={barcodeInputRef}
              type='text'
              className='form-control'
              value={scannedBarcodeValue}
              aria-label='Barcode value'
              placeholder={placeholder}
              disabled={isSearchDisabled}
              onChange={handleScanBarcodeOutput}
              onKeyUp={handleScanBarcodeOutput}
              onBlur={checkIfBarcodeInputIsNotFocused}
              onClick={setActiveScannerState}
            />
            <div className='input-group-append'>
              <ScanButton
                state={state}
                isLoading={isLoading}
                onSubmit={handleConfirmScanManually}
              />
            </div>
          </div>
        </section>
      )}

      {barcodeError && (
        <Alert name='qr-error' variant='danger' className='text-center'>
          {errorMessage}
          {lastScannedBarcodeValue && (
            <>
              <br />
              <small>
                <strong>Last scanned value:</strong> {lastScannedBarcodeValue}
              </small>
            </>
          )}
        </Alert>
      )}
      {!barcodeError && showInstructions && (
        <Alert name='qr-instructions' variant='info' className='text-center'>
          <span>{instructionText}</span>
        </Alert>
      )}
    </>
  );
};

BarcodeScan.propTypes = {
  instructionText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

BarcodeScan.defaultProps = {
  placeholder: 'Scan Barcode',
};

export default BarcodeScan;
