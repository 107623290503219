import create from 'zustand';

const useBarcodePanelStore = create(set => ({
  scannedBarcodeValue: '',
  barcodeError: false,
  errorMessage: '',
  showInstructions: true,

  setScannedBarcodeValue: ({ scannedBarcodeValue, ...props }) => {
    set({
      scannedBarcodeValue,
      showInstructions: false,
      barcodeError: false,
      errorMessage: '',
      ...props,
    });
  },

  setBarcodeError: ({ errorMessage, ...props }) =>
    set({
      errorMessage,
      barcodeError: !!errorMessage,
      ...props,
    }),

  resetBarcodeValue: () =>
    set({
      barcodeError: false,
      errorMessage: '',
      scannedBarcodeValue: '',
    }),
}));

export default useBarcodePanelStore;
