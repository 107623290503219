import _uniq from 'lodash/uniq';

export const FEATURES = {
  BOEING_ORDER_FIELDS: 'boeing-order-fields',
  AMAZON_ORDER_FIELDS: 'amazon-order-fields',
  MATERIAL_MANAGEMENT: 'material-management',
  RUN_TIME_TRACKING: 'run-time-tracking',
  SINTAVIA_ORDER_FIELDS: 'sintavia-order-fields',
  USER_RUN_TIME_TRACKING: 'user-run-time-tracking',
  SHIPMENT_FOR_ORDER: 'shipment-for-order',
  BEEHIVE3D_ORDER_FIELDS: 'beehive3d-order-fields',
  MATERIAL_TEST_PANEL: 'material-test-panel',
  POWDER_WORKFLOW: 'powder-workflow',
  QR_PRINTS_TRAVELER: 'qr-prints-traveler',
  POC_UK_ORDER_FIELDS: 'the-poc-uk-order-fields',
  TOOLING_STOCK: 'tooling-stock',
  EXPERIMENT_ALPHA: 'experiment-alpha',
  EXTERNAL_BUILD_ID: 'external-build-id',
};

export function isFeatureEnabled(features, featureName) {
  return features?.some(feature => feature.name === featureName && feature.enabled);
}

// returns true if at least one of the `featureNames` is enabled
export const isSomeFeaturesEnabled = (features, featureNames) =>
  features.some(feature => featureNames.includes(feature.name) && feature.enabled);

/* Returns true if all `featureNames` are enabled */
export const isAllFeaturesEnabled = (features, featureNames) => {
  const filteredFeatures = _uniq(
    features.filter(feature => featureNames.includes(feature.name) && feature.enabled)
  );
  return filteredFeatures.length === _uniq(featureNames).length;
};
