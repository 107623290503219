import { faBarcode } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BarcodePanel from 'src/components/barcode-panel';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Feature from 'src/components/feature';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import useBarcodePanelStore from 'src/stores/useBarcodePanelStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
} from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import { FEATURES } from 'src/utils/features';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { handleConvertedQuantityChange, validateMultipleZeros } from 'src/utils/validation';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';


const MachineUnloadChamber = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(null);
  const [batchSelectedRun, setBatchSelectedRun] = useState(null);
  const [subLocation, setSubLocation] = useState(undefined);
  const [batchRuns, setBatchRuns] = useState([]);
  const [runActualsByRunUri, setRunActualsByRunUri] = useState({});
  const [unloadedQuantityBase, setUnloadedQuantityBase] = useState(0);
  const [unloadedQuantityConverted, setUnloadedQuantityConverted] = useState('');

  const quantityInMachine = batch?.at_machine ? batch.quantity : 0;

  const {
    scannedBarcodeValue,
    setScannedBarcodeValue,
    resetBarcodeValue,
  } = useBarcodePanelStore();

  const {
    openActionPanel,
  } = useActionPanelStore();

  const setQuantity = (quantity, units) => {
    setUnloadedQuantityBase(quantity);

    // Convert the initial unloaded quantity to user's preferred units
    const conversionResult = convertToUserUnits(quantity, units);
    setUnloadedQuantityConverted(conversionResult.quantity);
  };

  const navigate = useNavigate();

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();

      if (!batch) return;

      const [subLocation, runActualsAdditiveResponse] = await Promise.all([
        api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json(),
        api.get(`${API_RESOURCES.RUN_ACTUALS_ADDITIVE}/`, {
          searchParams: {
            'filter[material_batch]': batch.uri,
          },
        }).json(),
      ]);

      const runActualsAdditive = runActualsAdditiveResponse?.resources || [];

      const { resources: runActuals } = !_isEmpty(runActualsAdditive) ?
        await api.get(`${API_RESOURCES.RUN_ACTUALS}/`, {
          searchParams: {
            'filter[uri]': _map(runActualsAdditive, 'run_actual'),
          },
        }).json() : [];

      const { resources: runs } = !_isEmpty(runActuals) ?
        await api.get(`${API_RESOURCES.RUN}/`, {
          searchParams: {
            'filter[uri]': _map(runActuals, 'run'),
          },
        }).json() : [];


      const runActualsByRunUri = _keyBy(runActuals, 'run');

      setBatch(batch);
      setSubLocation(subLocation);
      setBatchRuns(runs || []);
      setRunActualsByRunUri(runActualsByRunUri || {});

      const quantityInMachine = batch.at_machine ? batch.quantity : 0;
      setQuantity(quantityInMachine, batch.units);

    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => void getInitialData(), [batchUUID]);

  // Convert batch quantity to user's preferred units
  const { quantity: convertedBatchQuantity, units: convertedBatchUnits } =
    useMemo(() => {
      if (batch) {
        return convertToUserUnits(batch.quantity, batch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [batch]);

  const handleUnloadedQuantityChange = (e) => {
    handleConvertedQuantityChange({
      e,
      setBaseQuantity: setUnloadedQuantityBase,
      setConvertedQuantity: setUnloadedQuantityConverted,
      batchQuantity: batch.quantity,
      batchUnits: batch.units,
      convertedBatchQuantity,
      convertedBatchUnits,
      validateMultipleZeros,
    });
  };

  const handleOpenExternalBuildIdBarcodeScanner = () => {
    resetBarcodeValue();
    openActionPanel({
      title: 'Scan Barcode',
      content: <BarcodePanel
        placeholder="Scan External Build ID"
        instructionText="Please scan or type the barcode" />,
    });
  };


  const remainingQuantity = batch ? batch.quantity - unloadedQuantityBase : 0;
  const unloadedQuantityPercentage =
    batch && batch.quantity
      ? Math.round((unloadedQuantityBase / batch.quantity) * 100)
      : 0;

  const onActionUnloadedChamber = async (batchUri) => {
    let actionResult = null;
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          unload_amount: unloadedQuantityBase,
          unload_type: 'consumed',
        },
        source_batch: batchUri,
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
      };

      if (scannedBarcodeValue) {
        payload.metadata.external_build_id = scannedBarcodeValue;
      }

      if (batchSelectedRun) {
        payload.metadata.run = batchSelectedRun;
      }

      setSubmitting(true);
      actionResult = await materialActionRequest(payload).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    resetBarcodeValue();

    const { metadata: { resulting_batch: unloadedBatch } } = actionResult;

    const run = batchSelectedRun ?
      batchRuns.find((run) => run.uri === batchSelectedRun) :
      null;

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(unloadedBatch) },
      {
        action: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
        batch: getUuid(unloadedBatch),
        quantity: unloadedQuantityBase,
        remainingQuantity,
        subLocation: subLocation.name,
        printerUri: batch.at_machine,
        runUUID: getUuid(run?.uri) || '',
        buildID: scannedBarcodeValue,
      }));

    return new Promise(() => {
    });
  };

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  const handleSetBatchRun = (uri) => {
    setBatchSelectedRun(uri);
    // Get the Material Used from the selected Run
    const runActualsBaseMaterialUsed = runActualsByRunUri[uri]?.additive?.base_material_used || 0;

    // Do not set the Quantity if the Material Used is 0 or not defined
    if (!runActualsBaseMaterialUsed) {
      // Run Selector is not set.
      setQuantity(quantityInMachine, batch.units);
      return;
    }

    // If the Material Used is greater than the Quantity in the Machine, set the Quantity to the Quantity in the Machine

    if ((runActualsBaseMaterialUsed > quantityInMachine)) {
      setQuantity(quantityInMachine, batch.units);
      return;
    }

    // Set the Quantity to the Material Used
    setQuantity(runActualsBaseMaterialUsed, batch.units);
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER}
      isLoading={isLoading}
    >
      {batch &&
        <>
          <div className="alert alert-warning" role="alert">
            <b>Amount of material originally loaded into machine:</b>&nbsp;
            <ConvertedUnits quantity={quantityInMachine} units={batch.units} />
          </div>
          <div className="alert">
            <div>
              <label>
                Record the weight of the build as extracted from the build chamber
                (this may include trapped powder). If you cannot weigh the build,
                please enter an estimated build weight based on the volume of the build and the used material&apos;s
                density.
              </label>
              <Feature isInverted featureName={FEATURES.EXTERNAL_BUILD_ID}>
                {!_isEmpty(batchRuns) &&
                  <select
                    className="form-control mt-4 mb-4"
                    value={batchSelectedRun?.uri}
                    onChange={(event) => handleSetBatchRun(event.target.value)}
                  >
                    <option value="">Select Run</option>
                    {batchRuns.map((run) => (
                      <option key={run?.uri} value={run?.uri}>{run?.name}</option>
                    ))}
                  </select>}
              </Feature>
              <input
                name="remainingQuantity"
                min="0"
                max={quantityInMachine}
                type="number"
                value={unloadedQuantityConverted}
                onChange={handleUnloadedQuantityChange}
              />
              &nbsp;<span>{convertedBatchUnits} ({unloadedQuantityPercentage}%)</span>

              <Feature featureName={FEATURES.EXTERNAL_BUILD_ID}>
                <div className="input-group mt-4 mb-4 d-flex align-items-center">
                  <input
                    name="storedAmount"
                    value={scannedBarcodeValue}
                    type="text"
                    aria-label="External Build ID"
                    placeholder="Enter Build ID"
                    className="form-control"
                    onChange={(event) =>
                      setScannedBarcodeValue({ scannedBarcodeValue: event.target.value })}
                  />
                  <button
                    type="button" className="btn btn-link btn-sm barcode-scan-btn"
                    onClick={handleOpenExternalBuildIdBarcodeScanner}
                  >
                    <FontAwesomeIcon icon={faBarcode} className="spacer-left" />
                  </button>
                </div>
              </Feature>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary btn-block"
            disabled={!batch.at_machine || isSubmitting}
            onClick={() => onActionUnloadedChamber(batch.uri)}
          >
            Save
          </button>
        </>}

    </ActionPage>
  );
};

MachineUnloadChamber.propTypes = {
  user: userPropType,
};

MachineUnloadChamber.defaultProps = {
  user: null,
};

export default MachineUnloadChamber;
